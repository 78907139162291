


a {
    transition: none;
    color: #1E74FD;
    outline: 0 !important;
    text-decoration: none;
  }
  
  a:hover, a:active, a:focus {
    outline: 0 !important;
    color: #1E74FD;
    text-decoration: none;
  }
  
  button {
    outline: 0 !important;
  }
  
  button:hover, button:active, button:focus {
    outline: 0 !important;
  }
  
  .item img{
    width: 26px;
  }
  .appBottomMenu {
    min-height: 90px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #E1E1E1;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .appBottomMenu.no-border {
    border: 0 !important;
    box-shadow: 0 !important;
  }
  
  .appBottomMenu.rounded {
    border-radius: 24px 24px 0 0 !important;
  }
  
  .appBottomMenu.rounded .item:before {
    display: none;
  }
  
  .appBottomMenu .item {
    font-size: 10px;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    height: 56px;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .appBottomMenu .item:before {
    content: '';
    display: block;
    height: 2px;
    border-radius: 0 0 10px 10px;
    background: transparent;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 0;
  }
  
  .appBottomMenu .item .col {
    width: 100%;
    padding: 0 4px;
    text-align: center;
  }
  
  .appBottomMenu .item i.bi,
  .appBottomMenu .item i.icon,
  .appBottomMenu .item ion-icon {
    display: inline-flex;
    margin: 1px auto 3px auto;
    font-size: 26px;
    line-height: 1em;
    color: #1560BD;
    transition: none;
    display: block;
    margin-top: 1px;
    margin-bottom: 3px;
  }
  
  .appBottomMenu .item .action-button {
    display: inline-flex;
    width: 50px;
    height: 50px;
    margin-left: -5px;
    margin-right: -5px;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    background: #1E74FD;
  }
  
  .appBottomMenu .item .action-button.large {
    width: 60px;
    height: 60px;
    margin-top: -20px;
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .appBottomMenu .item .action-button i.bi,
  .appBottomMenu .item .action-button i.icon,
  .appBottomMenu .item .action-button ion-icon {
    color: #FFF !important;
    margin: 0 !important;
    line-height: 0 !important;
  }
  
  .appBottomMenu .item strong {
    margin-top: 10px;
    display: block;
    color: #1560BD;
    font-weight: 400;
    transition: none;
  }
  
  .appBottomMenu .item:active {
    opacity: .8;
  }
  
  .appBottomMenu .item.active:before {
    background: transparent;
  }
  
  .appBottomMenu .item.active i.bi,
  .appBottomMenu .item.active i.icon,
  .appBottomMenu .item.active ion-icon,
  .appBottomMenu .item.active strong {
    color: #1560BD !important;
    font-weight: 500;
  }
  
  .appBottomMenu .item:hover i.bi,
  .appBottomMenu .item:hover i.icon,
  .appBottomMenu .item:hover ion-icon,
  .appBottomMenu .item:hover strong {
    color: #141515;
  }
  
  .appBottomMenu.text-light {
    color: #FFF;
  }
  
  .appBottomMenu.text-light .item {
    color: #FFF;
    opacity: .7;
  }
  
  .appBottomMenu.text-light .item i.bi,
  .appBottomMenu.text-light .item i.icon,
  .appBottomMenu.text-light .item ion-icon,
  .appBottomMenu.text-light .item strong {
    color: #FFF;
  }
  
  .appBottomMenu.text-light .item.active {
    opacity: 1;
  }
  
  .appBottomMenu.text-light .item.active i.bi,
  .appBottomMenu.text-light .item.active i.icon,
  .appBottomMenu.text-light .item.active ion-icon,
  .appBottomMenu.text-light .item.active strong {
    color: #FFF !important;
  }
  
  .appBottomMenu.bg-primary, .appBottomMenu.bg-secondary, .appBottomMenu.bg-success, .appBottomMenu.bg-warning, .appBottomMenu.bg-danger, .appBottomMenu.bg-info, .appBottomMenu.bg-light, .appBottomMenu.bg-dark {
    border: 0;
  }
  
  .appBottomMenu.bg-primary .item:before, .appBottomMenu.bg-secondary .item:before, .appBottomMenu.bg-success .item:before, .appBottomMenu.bg-warning .item:before, .appBottomMenu.bg-danger .item:before, .appBottomMenu.bg-info .item:before, .appBottomMenu.bg-light .item:before, .appBottomMenu.bg-dark .item:before {
    display: none;
  }
  

  
  #appCapsule {
    padding: 26px 20px 36px;
    margin-bottom: env(safe-area-inset-bottom);
    margin-top: env(safe-area-inset-top);
  }
  
 
  .section {
    padding: 0 16px;
  }
  
  
  h1 {
    font-size: 34px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 28px;
  }
  
  h3 {
    font-size: 20px;
  }
  
  h4 {
    font-size: 15px;
    font-weight: 500;
  }
  
 
  ion-icon {
    --ionicon-stroke-width: 32px;
  }
  
  
  .appBottomMenu .badge {
    min-width: 16px;
    height: 16px;
    line-height: 9px !important;
    font-size: 10px;
    padding: 0 4px !important;
    position: absolute;
    right: 50%;
    transform: translateX(120%);
    top: 10px;
  }
  
  .appBottomMenu .badge-empty {
    transform: translateX(200%);
    top: 10px;
  }
  