@font-face {
  font-family: 'arialItelic';
  src: url('../../font/ArialCEItalic.ttf') format('truetype');
}
.card {
    background: #1560BD;
    border-radius: 16px;
    border: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
  .card ion-icon{
    color: #fff;
    font-size: 36px;
  }
  .card .card-body {
    padding: 24px 36px;
    line-height: 1.4em;
  }
  
  .card .card-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .card  .card-text{
    color: #fff;
    font-size: 16px;
  }
  .card .btn{
    width: 100%;
    color: #fff;
    font-size: 16px;
    padding: 14px;
    margin: 25px 0px;
    border-color: #fff;
  }
  .fontIt{
    font-family: arialItelic;
  }