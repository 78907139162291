.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
}
h2{
    font-size: 30px;
    font-weight: normal;
    text-align: center;
}
h3{
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
}
.daily-income{
    text-align: left;
    background-color: #1560BD;
    margin-bottom: 20%;
    font-size: 16px;
}
.calendar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
}
.calendaricon{
    color: #1560BD;
    vertical-align: text-bottom;
    font-size: 21px;
}
.month {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}
.monthname{
    font-size: 17px;
}
.analysis{
    margin-top: 40px;
}
.astrik{
    font-size: 88px;
    color: #1560BD;
    margin-bottom: 3px;
}
.atext{
    font-size: 16px;
    text-align: center;
    color: #000;
    margin-bottom: 30px;
}
.dailybtn{
    color: #000;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #000;
    margin-bottom: 20px;
}
.dailybtn:focus{
    background-color: #fff;
}
.previous{
    background-color: #F2F2F2;
    color: #000;
    font-size: 16px;
}
.previous:focus{
    background-color: #F2F2F2;
}
.total-txt{
    color: #1560BD;
    font-size: 16px;
    text-align: center;
    margin: 30px 0px;
    font-weight: bold;
}

 .day:hover {
    background-color: #000;
    color: #fff;
    border-radius: 50%;
}

.month-total {
    position: absolute;
    bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #1560BD;
}

.month-details {
    width: 100%;
}

.month-details h3{
    color: #1560BD;
}
.date-item span:nth-child(2){
    float: right;
}

.date-item:hover {
    /* background-color: #f0f0f0; */
}

.date-item div{
  font-weight: normal;
}
.date-details {
    margin-top: 5px;
    padding-left: 20px;
}

.date-details > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.total {
    font-weight: bold;
}
.turnover{
    color: #1560BD;
    font-size: 16px;
}
.add-button {
    background-color: #1560BD;
    color: white;
    margin-bottom: 20px;
    border: none;
    border-radius: 35%;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    /* position: fixed; */
    bottom: 20px;
    right: 20px;
}

.add-button:hover {
    background-color: #0056b3;
}

.inputs-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.input-group {
    margin-bottom: 14px;
    display: block;
}
.input-group label{
    display: block;
}
input {
    width: 100%;
    padding: 9px;
}

button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    width: 100%;
}

button:hover {
    background-color: #0056b3;
}

.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 14px;
    border: 1px solid #000;
}

.month-navigation {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-bottom: 10px;
}
.month-navigation span{
text-align: center;
}
.month-navigation button{
    background-color: transparent;
    color: #000;
}
.month.clickable {
    cursor: pointer;
    color: #1560BD;
    opacity: 100%;
    border-color: #1560BD;
}

.month.disabled {
    cursor: not-allowed;
}

.calendar-header span{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 2px;
    text-align: center;
    cursor: pointer;
}

.calendar-grid,.calendar-header  {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    color: #A0A0A0;
}

.day {
    display: inline-block;
    color: #000;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 2px;
    text-align: center;
    cursor: pointer;
}


.monthly-total{
    text-align: center;
margin: 20px 0px;
color: #0056b3;
font-weight: 600;
}
.daily-total-display{
    text-align: center;
    margin: 20px 0px;
    color: #0056b3;
    font-weight: 600;
}
.input-group label{
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
}
input{
    border: 1px solid #000;
    border-radius: 5px !important;
}
.save{
    background-color: #000;
    color: #fff;
    margin: 30px 0px;
}




.date-item {
    /* display: grid; */
    grid-template-columns: 1fr auto;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    padding: 10px 0;
    width: 100%;
    align-items: center;
}

.date-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px 0px;
    /* background-color: #f9f9f9; */
    /* border-bottom: 1px solid #ddd; */
    margin-top: 5px;
    width: 100%;
}

.date-details > div {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.date-details > div span:first-child {
    color: #1560BD;
}

.date-details > div span:last-child {
    color: #1560BD;
    text-align: right;
}

.total {
    display: grid;
    grid-template-columns: 1fr auto;
    font-weight: bold;
    text-align: center;
    color: #1560BD;
    padding: 10px 0;
    margin-top: 10px;
    border-bottom: none !important;
}