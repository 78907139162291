@font-face {
    font-family: 'Moonhouse';
    src: url('../../font/moonhouse.ttf') format('truetype');
  }
.navbar {
    position: sticky;
    top: env(safe-area-inset-top);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 25px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-bottom-left-radius: 70px; 
    border-bottom-right-radius: 0px; 
  }
  
  .icon {
    font-size: 26px;
    cursor: pointer;
    color: #1560BD; 
  }
  
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logoo{
    display: grid;
    text-align: center;
  }
  .logo {
    font-family: 'Moonhouse', sans-serif;
    font-size: 80px;
    font-weight: normal;
    color: #0056b3;
    text-transform: lowercase;
  }
  
  .url {
    font-size: 14px;
    color: #0056b3;
    margin-top: 10px;
  }
  


 

.iconbar {
    display: flex;
    flex-direction: column;
    gap: 4px; 
    direction: rtl;
}

.bar {
    background-color: #1560BD;
    height: 3px;
    border-radius: 10px;
    float: left;
    direction: rtl;
}

.bar1 {
    width: 30px; 
}

.bar2 {
    width: 25px; 
}

.bar3 {
    width: 20px; 
}
