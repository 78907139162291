@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@font-face {
  font-family: 'arial';
  src: url('./assets/font/Arial-Regular.ttf') format('truetype');
}
body {
  font-family: "arial", sans-serif;
  font-size: 15px;
  line-height: 1.55rem;
  letter-spacing: -0.015rem;
  color: #4F5050;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0;
}

::selection {
  background: rgba(30, 116, 253, 0.3);
}



.large-device-message {
  color: #ff0000;
  font-size: 24px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #ff0000;
  border-radius: 10px;
}

.app-content {
  width: 100%;
}
